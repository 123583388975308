import React from "react";
import Layout from "../components/layout";
import Button from "../components/Button";
import NextStyles from "../styles/pages/next.module.scss";

import backgroundImage from "../images/bg-next.jpg";

export default function NextPage() {
    console.log("next");
    return (
        <Layout background={backgroundImage}>
            <div className={NextStyles.btnWrapper}>
                <Button to="/product-page" variant="next" label="Далі" />
            </div>
        </Layout>
    );
}